<template>
  <div>
    <div class="header-container">
      <div>
        <v-breadcrumbs>
          <v-breadcrumbs-item class="breadcrumbs-hover-default" style="font-size: 16px;">
            <v-icon style="font-size: 25px;">mdi-account-search</v-icon>
            <span @click="main()">ການຄັດເລືອກພະນັກງານ (HR)</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item class="breadcrumbs-hover" v-if="$route.name === 'hr.selected.applicant.detail'">
            <span v-show="true">/</span>
            <span>ລາຍລະອຽດ</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item class="breadcrumbs-hover" v-if="$route.name === 'director_approve_recruitment.detail'">
            <span v-show="true">/</span>
            <span>{{ $t("recruit_ment.detail.title") }}</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    main() {
      this.$router
        .push({
          name: "hr.selected.applicant",
        })
        .catch(() => { });
    },
  },
};
</script>

<style scoped lang="scss">
.breadcrumbs-hover {
  font-size: 18px;
}

.content {
  margin-top: 10px;
  padding-top: 30px;
  border-top: 1px solid rgb(95, 95, 95);
}
</style>
